const initialState = () => ({
	snackbarState: false,
	snackbarText: "",
	snackbarTime: -1,
	showOverlayLoader: false,
	snackbarColor: "",
});
export default {
	namespaced: true,
	state: initialState,
	getters: {
		snackbarState: (state) => state.snackbarState,
		snackbarText: (state) => state.snackbarText,
		snackbarTime: (state) => state.snackbarTime,
		snackbarColor: (state) => state.snackbarColor,
		showOverlayLoader: (state) => state.showOverlayLoader,
	},
	mutations: {
		openSnackbar(state, { text = "", time = 3000, type = "" } = {}) {
			state.snackbarTime = time;
			state.snackbarText = text;
			state.snackbarState = true;
			switch (type) {
				case "error":
					state.snackbarColor = "#BF2600";
					break;
				case "success":
					state.snackbarColor = "#00875A";
					break;
				case "":
					state.snackbarColor = "#007fff";
					break;
			}
		},
		closeSnackbar(state) {
			state.snackbarState = false;
		},
		openOverlayLoader(state) {
			state.showOverlayLoader = true;
		},
		closeOverlayLoader(state) {
			state.showOverlayLoader = false;
		},
	},
	actions: {},
	modules: {},
};
