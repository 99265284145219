import Vue from "vue";
import Auth from "./Auth.vue";
import router from "./router";
import store from "./store";

import images from "./assets/getAssets.js";
import { Constant } from "./Constants";
import APIManager from "./network/base/APIManager.js";
import vuetify from './plugins/vuetify'

function getQueryParams(query) {
	var params = new URL(document.location).searchParams;
	var value = params.get(query);
	return value;
}

Vue.config.productionTip = false;
Vue.prototype.appImages = images;
Vue.prototype.Constant = Constant;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(Auth)
}).$mount("#app");

window.onload = () => {
	let language = getQueryParams("lang");

	if (!language) {
		language = "en";
	}

	var IS_MAC = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
	window.IS_MAC = IS_MAC;

	var apiManager = new APIManager();
	window.apiManager = apiManager;
	window.apiManager.uiLanguage = language;

	apiManager.init();
	apiManager.start();
};
