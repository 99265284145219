<template>
	<div class="popup">
		<CanvasPopup> </CanvasPopup>
	</div>
</template>

<script>
import CanvasPopup from "@/components/sharedComponents/CanvasPopup.vue";
export default {
	components: {
		CanvasPopup,
	},
};
</script>

<style></style>
