import {
	getAllProjects,
	createProject,
	getProjectDetails,
	updateProject,
	uploadFloorImage,
	getPrimaryUnits,
	getPipingSizes,
	removeFloorImage,
	deleteProject,
} from "../../network/project.service";

const initialState = () => ({});

export default {
	namespaced: true,
	state: initialState(),
	mutations: {},
	actions: {
		async fetchPrimaryUnits({ commit }) {
			try {
				// commit("uiManager/openOverlayLoader", null, { root: true });
				const userData = await getPrimaryUnits();
				return userData.data;
			} catch (e) {
				console.error(e);
			}
		},
		async fetchPipingSizes({ commit }, payload) {
			try {
				// commit("uiManager/openOverlayLoader", null, { root: true });
				const pipingSizes = await getPipingSizes(payload);
				console.log(pipingSizes);
				return pipingSizes.data;
			} catch (e) {
				console.error(e);
			}
		},
		async fetchAllProjects({ commit }, payload) {
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await getAllProjects(payload);
				return response.data;
			} catch (e) {
				console.error(e);
			}
		},
		async fetchProjectDetails({ commit }, payload) {
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await getProjectDetails(payload);
				return response;
			} catch (e) {
				console.error(e);
			}
		},
		async removeProjects({ commit }, payload) {
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await deleteProject(payload);
				return response;
			} catch (e) {
				console.error(e);
			}
		},
		async addProject({ commit }, payload) {
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await createProject(payload);
				if (response.data.error) {
					commit(
						"uiManager/openSnackbar",
						{ text: response.data.error, type: "error" },
						{ root: true }
					);
				} else {
					commit(
						"uiManager/openSnackbar",
						{ text: "Project created!", type: "success" },
						{ root: true }
					);
				}
				return response;
			} catch (e) {
				commit(
					"uiManager/openSnackbar",
					{ text: "Failed to create projects!", type: "error" },
					{ root: true }
				);
				console.error(e);
			}
		},
		async updateProjectDetails({ commit }, payload) {
			const { showToast } = payload;
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await updateProject(payload);
				if (showToast) {
					commit(
						"uiManager/openSnackbar",
						{ text: "Project Updated!", type: "success" },
						{ root: true }
					);
				}
				return response;
			} catch (e) {
				commit(
					"uiManager/openSnackbar",
					{ text: "Failed to update projects!", type: "error" },
					{ root: true }
				);
				console.error(e);
			}
		},
		async uploadProjectFloorImage({ commit }, payload) {
			console.log(payload);
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await uploadFloorImage(payload);
				commit(
					"uiManager/openSnackbar",
					{ text: "Image uploaded!", type: "success" },
					{ root: true }
				);
				return response;
			} catch (e) {
				commit(
					"uiManager/openSnackbar",
					{ text: "Failed to upload image!", type: "error" },
					{ root: true }
				);
				console.error(e);
			}
		},
		async deleteProjectFloorImage({ commit }, payload) {
			console.log(payload);
			try {
				commit("uiManager/openOverlayLoader", null, { root: true });
				const response = await removeFloorImage(payload);
				commit(
					"uiManager/openSnackbar",
					{ text: "Image removed!", type: "success" },
					{ root: true }
				);
				return response;
			} catch (e) {
				commit(
					"uiManager/openSnackbar",
					{ text: "Failed to remove image!", type: "error" },
					{ root: true }
				);
				console.error(e);
			}
		},
	},
	getters: {},
};
