import Vue from "vue";
import VueRouter from "vue-router";
import projects from "../views/Projects.vue";
import projectSetup from "../views/ProjectSetup.vue";
import Popup from "../views/Popup.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/projects",
	},
	{
		path: "/projects",
		name: "projects",
		component: projects,
	},
	{
		path: "/projects/:id",
		name: "projects-setup",
		component: projectSetup,
	},
	{
		path: "/popup",
		name: "popup",
		component: Popup,
	},
];

const router = new VueRouter({
	routes,
});

export default router;
