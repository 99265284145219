<template>
	<v-app>
		<v-app-bar app dense color="#FFF" height="63px">
			<v-spacer></v-spacer>
			<v-tabs
				v-model="ActiveTab"
				align-with-title
				active-class="tabs-active"
			>
				<v-tabs-slider color="#007FFF"></v-tabs-slider>

				<v-tab
					v-for="tab in tabs"
					:key="tab"
					@click="handleTabsCLick(tab)"
				>
					{{ tab }}
				</v-tab>
			</v-tabs>
			<div class="logout-wrapper">
				<v-btn icon @click="logout">
					<v-icon> mdi-logout </v-icon>
				</v-btn>
			</div>
		</v-app-bar>

		<v-main>
			<router-view />
			<v-snackbar
				v-model="localSnackbarState"
				multi-line
				light
				:timeout="snackbarTime"
				text
				:color="snackbarColor"
				top
			>
				{{ snackbarText }}
				<template #action="{ attrs }">
					<v-btn text icon v-bind="attrs" @click.stop="closeSnackbar">
						<v-icon color="#091E42">mdi-close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
			<v-overlay :value="showOverlayLoader">
				<v-progress-circular
					indeterminate
					color="#007fff"
					size="70"
				></v-progress-circular>
			</v-overlay>
		</v-main>
	</v-app>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from "vuex";

export default {
	name: "App",

	data: () => ({
		ActiveTab: null,
		tabs: ["Projects"],
		localSnackbarState: false,
	}),
	computed: {
		...mapGetters("uiManager", [
			"snackbarState",
			"snackbarText",
			"snackbarTime",
			"snackbarColor",
			"showOverlayLoader",
		]),
	},
	watch: {
		snackbarState(nv) {
			this.localSnackbarState = nv;
		},
		localSnackbarState(nv) {
			if (!nv) {
				this.closeSnackbar();
			}
		},
	},
	mounted() {
		let routeName = this.$route.name;
		console.log(routeName);
		switch (routeName) {
			case "projects":
			case "projects-setup":
				this.ActiveTab = 0;
				break;
		}
	},
	methods: {
		...mapMutations("uiManager", ["openSnackbar", "closeSnackbar"]),
		...mapActions("adminAuth", ["logout"]),
		handleTabsCLick(tab) {
			switch (tab) {
				case "Projects":
					if (this.$route.name == "projects") return;
					this.$router.push("/projects");
					break;
			}
		},
	},
};
</script>

<style lang="scss">
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
	box-shadow: 0px -0.8999999761581421px 0px 0px #e2e2ea inset;
}
.v-tab {
	font-size: 0.875rem;
	font-weight: 500;
	letter-spacing: 0.0056rem;
	&:not(.v-tab--active) {
		color: #171725 !important;
	}
	&--active {
		color: $primary-blue;
	}
}
</style>
