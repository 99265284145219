const appBaseURL = process.env.VUE_APP_BASE_URL;

export default class APIManager {
	constructor() {
		this.appBaseURL = appBaseURL;

		this.requestAuth = {
			withCredentials: true,
			credentials: "include",
			headers: {
				//'Authorization': 'Bearer ' + this.getToken(),
				"Content-Type": "application/json",
			},
		};
	}

	init() {}

	start() {}

	getToken() {
		return localStorage.Authorization;
	}

	getRequestAuth() {
		this.requestAuth.headers.Authorization = "Bearer " + this.getToken();
		return this.requestAuth;
	}

	encodeQueryParams(params) {
		return (
			"?" +
			Object.keys(params)
				.map(function (key) {
					return (
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(params[key])
					);
				})
				.join("&")
		);
	}

	fetchProject() {}
}
