<template>
	<div class="popup">
		<div v-if="isShowCanvasPopup" class="popup-blur">
			<div class="popup_container">
				<div class="delete-popup">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						class="delete-popup-svg"
						@click="handleCanvasPopup"
					>
						<path
							d="M6.75781 17.2428L12.0008 11.9998L17.2438 17.2428M17.2438 6.75684L11.9998 11.9998L6.75781 6.75684"
							stroke="black"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div class="slot-container">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isShowCanvasPopup: true,
		};
	},
	methods: {
		handleCanvasPopup() {
			this.isShowCanvasPopup = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.popup {
	&_container {
		width: fit-content;
		height: fit-content;
		background: $primary-white;
		border-radius: 0.68rem;
		padding: 1.43rem;
		box-shadow: 0px 0.25rem 1.81rem 0px $secondary-box-shadow;

		position: relative;
		z-index: 99;
	}
	&-blur {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: $thirsary-background-color-3;

		z-index: 999;

		display: grid;
		place-items: center;
	}
}

.slot-container {
	height: fit-content;
	width: fit-content;
	margin: 0 auto;
}

.delete-popup {
	height: 1.5rem;
	width: 1.5rem;

	position: absolute;
	top: 1.43rem;
	right: 1.43rem;

	&:hover {
		cursor: pointer;
	}
	&-svg {
		height: 100%;
		width: 100%;
		stroke: $primary-black;
	}
}
</style>
