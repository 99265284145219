<template>
	<div class="popup item-center">
		<div class="popup_container item-center">
			<h2 class="popup_container-tittle">Enter New Project Details</h2>
			<v-container class="popup_container-project-name">
				<v-form ref="projectForm" lazy-validation>
					<v-row>
						<v-text-field
							v-model="formData.projectReference"
							:rules="notEmptyRule"
							label="Project Refrence"
							dense
							color="#007fff"
							outlined
							placeholder="Project Refrence"
							clearable
							required
						></v-text-field>
					</v-row>
					<v-row>
						<v-text-field
							v-model="formData.name"
							:rules="notEmptyRule"
							label="Name"
							color="#007fff"
							outlined
							dense
							placeholder="Name"
							clearable
							required
						></v-text-field>
					</v-row>
				</v-form>
			</v-container>
			<button class="primary-btn" @click="handleCreateProjectClick">
				Create Projects
			</button>
		</div>
		<div class="hidepopup" @click="handlePopUp"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			formData: {},
			notEmptyRule: [(v) => !!v || "Field is required"],
		};
	},
	methods: {
		handlePopUp() {
			this.$emit("popupHide");
		},
		handleCreateProjectClick() {
			if (!this.$refs.projectForm.validate()) return;
			this.$emit(
				"onCreateProjectClick",
				JSON.parse(JSON.stringify(this.formData))
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.popup {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 99;
	background: $secondary-background-color;

	&_container {
		width: 40%;
		height: auto;
		background-color: $primary-white;
		padding: 2rem 1rem 1rem 1rem;
		position: relative;
		z-index: 99;
		border-radius: 0.375rem;

		&-tittle {
			width: 80%;
			color: $text-color-2;
			font-size: 1.5rem;
			font-weight: 500;
			letter-spacing: 0.09px;
			padding-bottom: 0.8rem;
			text-align: center;
			border-bottom: 2px solid $secondary-border-color;
		}

		&-refrence-no,
		&-project-name {
			width: 80%;
			margin-top: 1rem;

			& > label {
				color: $text-color-3;
				font-size: 0.84rem;
				font-weight: 600;
				line-height: 1.2rem;

				&::before {
					content: "*";
					font-size: 1rem;
					color: $primary-tomato;
					font-size: 0.8rem;
					font-weight: 500;
					line-height: 1.14rem;
					margin-right: 0.2rem;
				}
			}

			& > input {
				width: 100%;
				border-radius: 6px;
				border: 1px solid $primary-border-color;
				background: $primary-white;
				box-shadow: 0px 1px 2px 0px $primary-box-shadow;
				margin-top: 0.3rem;
				padding: 0.3rem 0;

				&:focus {
					outline: none;
				}
			}
		}
	}
}

.hidepopup {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9;
}
</style>

<style lang="scss">
.primary-btn {
	background-color: $secondary-blue;
	color: $primary-white;

	font-size: 0.84rem;
	font-weight: 400;

	border-radius: 0.375rem;
	padding: 0.43rem 0.43rem 0.43rem 0.5rem;
	margin-top: 1.6rem;
}
</style>
