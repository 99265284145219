import axios from 'axios';

const environmentUrl = process.env.VUE_APP_BASE_URL;

const getToken = ()  => {
	// return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidXNlciIsIl9pZCI6IjY0YmI3Zjg4NWUyMzIxYjY2Y2FkYTEyNyIsImlhdCI6MTY5MTIxNzAzNywiZXhwIjoxNzIyNzUzMDM3fQ.d5ZFDd7E0Qu-TGME4V1sQf6N5oLXkUWq9UVAtd29FNA'
	return localStorage.Authorization;
}

export const axiosInstance = axios.create({
	baseURL: environmentUrl,
	timeout: 30000,
	headers: {'Authorization': "Bearer " + getToken()}
});