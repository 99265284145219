<template>
	<div>
		<app v-if="authorization"></app>
		<div v-else class="login-wrapper">
			<v-overlay>
				<v-progress-circular
					indeterminate
					color="#007fff"
					size="70"
				></v-progress-circular>
			</v-overlay>
		</div>
	</div>
</template>

<script>
import app from "./App.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
	name: "Auth",
	components: {
		app,
	},

	data: () => ({}),
	computed: {
		...mapGetters("adminAuth", ["authorization"]),
	},
	async mounted() {
		console.log(this.$route.query.authCode)
		if (this.$route.query.authCode) {
			const access_token = await this.getAccessToken(
				this.$route.query.authCode
			);
			localStorage.setItem("Authorization", access_token);
			console.log(access_token)
			this.$router.push("/projects");
			location.href = location.origin + location.pathname;
		} else {
			const token = window.localStorage.getItem("Authorization");
			if (token && token.length > 0) {
				this.setAuthorization(token);
			} else {
				this.fetchUserloginUrl();
			}
		}
	},
	methods: {
		...mapMutations("adminAuth", ["setAuthorization"]),
		...mapActions("adminAuth", ["fetchUserloginUrl", "getAccessToken"]),
	},
};
</script>

<style lang="scss" scoped>
.login-wrapper {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
