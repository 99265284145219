import {
	fetchUserloginUrl,
	getAccessToken,
	logout,
} from "../../network/project.service";

import axios from "axios";

const initialState = () => ({
	authorization: "",
});

export default {
	namespaced: true,
	state: initialState(),
	mutations: {
		setAuthorization(state, payload) {
			state.authorization = payload;
		},
	},
	actions: {
		async fetchUserloginUrl({}) {
			try {
				const response = await fetchUserloginUrl();
				open(response.data.loginUrl, "_self");
				console.log(response.data.redirectURL);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchAccessToken({}) {
			try {
				const response = await getAccessToken();
				console.log(response);
			} catch (e) {
				console.error(e);
			}
		},
		async logout({}) {
			try {
				localStorage.removeItem("Authorization");
				await logout();
				location.reload();
			} catch (e) {
				console.error(e);
			}
		},
		getAccessToken({},payload) {
			console.log(payload);
			return new Promise((resolve, reject) => {
				axios
					.get(
						`${process.env.VUE_APP_BASE_URL}Auth/v1.0/oauth2/access-token`,
						{
							headers: {
								Authorization: `Bearer ${payload}`,
							},
						}
					)
					.then((res) => {
						if (res.status === 200) {
							resolve(res.data.access_token);
						} else {
							reject(
								new Error(
									`Request failed with status: ${res.status}`
								)
							);
						}
					})
					.catch((error) => {
						reject(error);
					});
			});
		},
	},
	getters: {
		authorization: (state) => state.authorization,
	},
};
