<template>
	<v-data-table
		v-model="selectedProjects"
		class="elevation-0 custom-table"
		item-key="_id"
		:headers="userheaderdata"
		:items="userrowsdata"
		:server-items-length="totalProjects"
		show-select
		@update:options="handlePaginationChange"
		@input="$emit('projectSelected', selectedProjects)"
	>
		<template #[`item.Restore`]="{}">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="17"
				height="17"
				viewBox="0 0 17 17"
				fill="none"
			>
				<path
					d="M9.20825 2.125C7.5175 2.125 5.89599 2.79665 4.70045 3.99219C3.5049 5.18774 2.83325 6.80924 2.83325 8.5H0.708252L3.54159 11.3262L6.37492 8.5H4.24992C4.24992 5.75875 6.467 3.54167 9.20825 3.54167C11.9495 3.54167 14.1666 5.75875 14.1666 8.5C14.1666 11.2413 11.9495 13.4583 9.20825 13.4583C7.84117 13.4583 6.60159 12.8988 5.70909 11.9992L4.70325 13.005C5.29359 13.5986 5.9956 14.0694 6.7688 14.3904C7.542 14.7113 8.37109 14.876 9.20825 14.875C10.899 14.875 12.5205 14.2033 13.7161 13.0078C14.9116 11.8123 15.5833 10.1908 15.5833 8.5C15.5833 6.80924 14.9116 5.18774 13.7161 3.99219C12.5205 2.79665 10.899 2.125 9.20825 2.125ZM8.49992 5.66667V9.20833L11.5103 10.9933L12.0558 10.0867L9.56242 8.60625V5.66667H8.49992Z"
					fill="black"
				/>
			</svg>
		</template>
		<template #[`item.srNo`]="{ index }">
			<span>{{ index + 1 }}</span>
		</template>
		<template v-if="showDisabledProjects" #[`item.restore`]="{ item }">
			<span class="restore" @click="$emit('restoreProject', item)"
				><div class="restore-wrapper">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="13"
						viewBox="0 0 16 13"
						fill="none"
					>
						<path
							d="M9.20825 0.125C7.5175 0.125 5.89599 0.79665 4.70045 1.99219C3.5049 3.18774 2.83325 4.80924 2.83325 6.5H0.708252L3.54159 9.32625L6.37492 6.5H4.24992C4.24992 3.75875 6.467 1.54167 9.20825 1.54167C11.9495 1.54167 14.1666 3.75875 14.1666 6.5C14.1666 9.24125 11.9495 11.4583 9.20825 11.4583C7.84117 11.4583 6.60159 10.8988 5.70909 9.99917L4.70325 11.005C5.29359 11.5986 5.9956 12.0694 6.7688 12.3904C7.542 12.7113 8.37109 12.876 9.20825 12.875C10.899 12.875 12.5205 12.2033 13.7161 11.0078C14.9116 9.81226 15.5833 8.19075 15.5833 6.5C15.5833 4.80924 14.9116 3.18774 13.7161 1.99219C12.5205 0.79665 10.899 0.125 9.20825 0.125ZM8.49992 3.66667V7.20833L11.5103 8.99333L12.0558 8.08667L9.56242 6.60625V3.66667H8.49992Z"
							fill="black"
						/>
					</svg></div
			></span>
		</template>
		<template v-else #[`item.link`]="{ item }">
			<span class="go-to-projects" @click="handleOpenProject(item)">{{
				"Go To Project"
			}}</span>
		</template>
		<template #[`item.createdAt`]="{ item }">
			<span>{{ formatDate(item.createdAt) }}</span>
		</template>
		<template #[`item.updatedAt`]="{ item }">
			<span>{{ formatDate(item.updatedAt) }}</span>
		</template>
	</v-data-table>
</template>

<script>
export default {
	props: {
		userheaderdata: {
			type: Array,
			require: true,
			default: () => [],
		},
		userrowsdata: {
			type: Array,
			require: true,
			default: () => [],
		},
		page: {
			type: Number,
			require: true,
			default: 1,
		},
		limit: {
			type: Number,
			require: true,
			default: 10,
		},
		totalProjects: {
			type: Number,
			require: true,
			default: 0,
		},
		showDisabledProjects: {
			type: Boolean,
			require: true,
			default: false,
		},
	},

	data() {
		return {
			selectedProjects: [],
		};
	},
	methods: {
		handlePaginationChange(e) {
			const { itemsPerPage, page } = e;
			const payload = { page, limit: itemsPerPage };
			this.$emit("onPaginationChanges", payload);
		},
		handleOpenProject(project) {
			
			window.open(
				`${location.origin}/editor/?project=${project._id}`,
				"_blank"
			);
		},
		formatDate(date) {
			return date.split("T")[0];
		},
	},
};
</script>
<style lang="scss" scoped>
.go-to-projects {
	font-size: 0.875rem;
	font-weight: 500;
	&:hover {
		color: $primary-blue;
	}
}
.restore {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0;
	.restore-wrapper {
		height: 1.6875rem;
		width: 1.6875rem;
		display: flex;
		align-items: center;
		margin-left: 0;
		border-radius: 0.0625rem;
		background: #b5d1ff;
		justify-content: center;
		&:hover {
			box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.2);
		}
		cursor: pointer;
		svg {
			margin-left: 0;
		}
	}
}
</style>
<style lang="scss">
.v-data-table-header {
	background: $thirsary-background-color !important;
	height: 3.125rem;
}

.section-header {
	color: $primary-black !important;
	font-size: 0.85rem !important;
	font-weight: 600 !important;
	letter-spacing: 0.005rem !important;
}
tbody tr {
	width: 100% !important;
	color: rgba(0, 0, 0, 0.56);
	font-size: 0.8rem !important;
	font-weight: 500;
	letter-spacing: 0.005rem;
}
tbody tr:nth-child(even) {
	background: $thirsary-background-color-2 !important;
	cursor: pointer !important;
	height: 3.125rem;
}
tbody tr:nth-child(odd) {
	background: $primary-white !important;
	cursor: pointer !important;
	height: 3.125rem;
}
table :nth-child(1) {
	margin-left: 0.3rem;
}
</style>
