<template>
	<div class="project-setup-wrapper">
		<div class="header">
			<div class="header-text">Project Setup</div>
			<div v-if="projectDetails" class="project-details-wrapper">
				<div class="project-detail project-detail--ref">
					<div class="project-detail-label">
						Project Reference No:
					</div>
					<div class="project-detail-value">
						{{ projectDetails.projectReference }}
					</div>
				</div>
				<div class="project-detail project-detail--name">
					<div class="project-detail-label">Project Name:</div>
					<div class="project-detail-value">
						{{ projectDetails.name }}
					</div>
				</div>
				<div class="project-detail project-detail--version">
					<div class="project-detail-label">Versions:</div>
					<div class="project-detail-value">
						{{ projectDetails.version }}
					</div>
				</div>
			</div>
		</div>
		<div class="project-settings-wrapper">
			<div class="select-units-wrapper">
				<div class="select-units-label label">
					Select Projects Units:
				</div>
				<div class="select-units-options-wrapper">
					<div
						class="select-units-options"
						:class="disableSelectingunits ? 'disble-units' : ''"
					>
						<v-menu offset-y>
							<template #activator="{ on, attrs }">
								<div
									class="select-units-options-value select-units-options-value--imperial"
									:class="
										selectedUnit &&
										selectedUnit.type == 'metric'
											? 'selected'
											: ''
									"
									v-bind="attrs"
									v-on="on"
								>
									{{
										`metric ${
											selectedUnit &&
											selectedUnit.type == "metric"
												? `(${selectedUnit.shorthand})`
												: ""
										}`
									}}
								</div>
							</template>
							<v-list>
								<v-list-item
									v-for="unit in metricUnits"
									:key="unit._id"
									@click="handlePrimaryUnitsClick(unit)"
								>
									<v-list-item-title>{{
										`${unit.fullName}(${unit.shorthand})`
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>

						<v-menu offset-y>
							<template #activator="{ on, attrs }">
								<div
									class="select-units-options-value select-units-options-value--imperial"
									:class="
										selectedUnit &&
										selectedUnit.type == 'imperial'
											? 'selected'
											: ''
									"
									v-bind="attrs"
									v-on="on"
								>
									{{
										`imperial ${
											selectedUnit &&
											selectedUnit.type == "imperial"
												? `(${selectedUnit.shorthand})`
												: ""
										}`
									}}
								</div>
							</template>
							<v-list>
								<v-list-item
									v-for="unit in imperialUnits"
									:key="unit._id"
									@click="handlePrimaryUnitsClick(unit)"
								>
									<v-list-item-title>{{
										`${unit.fullName}(${unit.shorthand})`
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<!-- <div class="selected-units-hint">
							Project unit selected is Imperial
						</div> -->
					</div>
					<div class="select-units-warning">
						<div class="warning-icon">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
							>
								<path
									d="M9 16.5C4.85775 16.5 1.5 13.1422 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1422 13.1422 16.5 9 16.5ZM9 15C10.5913 15 12.1174 14.3679 13.2426 13.2426C14.3679 12.1174 15 10.5913 15 9C15 7.4087 14.3679 5.88258 13.2426 4.75736C12.1174 3.63214 10.5913 3 9 3C7.4087 3 5.88258 3.63214 4.75736 4.75736C3.63214 5.88258 3 7.4087 3 9C3 10.5913 3.63214 12.1174 4.75736 13.2426C5.88258 14.3679 7.4087 15 9 15ZM9 5.25C9.19891 5.25 9.38968 5.32902 9.53033 5.46967C9.67098 5.61032 9.75 5.80109 9.75 6V9.75C9.75 9.94891 9.67098 10.1397 9.53033 10.2803C9.38968 10.421 9.19891 10.5 9 10.5C8.80109 10.5 8.61032 10.421 8.46967 10.2803C8.32902 10.1397 8.25 9.94891 8.25 9.75V6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967C8.61032 5.32902 8.80109 5.25 9 5.25ZM9 12.75C8.80109 12.75 8.61032 12.671 8.46967 12.5303C8.32902 12.3897 8.25 12.1989 8.25 12C8.25 11.8011 8.32902 11.6103 8.46967 11.4697C8.61032 11.329 8.80109 11.25 9 11.25C9.19891 11.25 9.38968 11.329 9.53033 11.4697C9.67098 11.6103 9.75 11.8011 9.75 12C9.75 12.1989 9.67098 12.3897 9.53033 12.5303C9.38968 12.671 9.19891 12.75 9 12.75Z"
									fill="#D7A648"
								/>
							</svg>
						</div>
						<div class="warning-text">
							Once the Project unit are selected - they can not be
							changed for a project
						</div>
					</div>
				</div>
			</div>

			<div v-if="selectedUnit" class="floor-setup-wrapper">
				<div class="floor-setup-label label">Floor Setup:</div>
				<div class="floor-setup">
					<template
						v-if="
							projectDetails &&
							projectDetails.floorList &&
							projectDetails.floorList.length
						"
					>
						<v-row
							v-for="floor in projectDetails.floorList"
							:key="floor.uuid"
							:ref="`floor${floor.uuid}`"
							dense
							align="center"
						>
							<v-col class="level-name">
								<v-text-field
									v-model="floor.levelName"
									outlined
									dense
									hide-details
									placeholder="Enter Level Name*"
									label="Enter Level Name*"
									color="#007fff"
									@change="updateProject('floorList')"
								>
								</v-text-field>
							</v-col>
							<v-col class="level-height">
								<v-text-field
									v-model="floor.levelHeight"
									outlined
									type="number"
									dense
									hide-details
									placeholder="Enter Level Height*"
									label="Enter Level Height*"
									color="#007fff"
									@change="handleHeightChange"
								>
								</v-text-field>
							</v-col>
							<v-col class="elevation-base">
								<v-text-field
									v-model="floor.elevationBase"
									readonly
									outlined
									dense
									hide-details
									placeholder="Elevation Base"
									label="Elevation Base"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<v-col class="elevation-top">
								<v-text-field
									v-model="floor.elevationTop"
									readonly
									outlined
									dense
									hide-details
									placeholder="Elevation Top"
									label="Elevation Top"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<!-- <div class="floor-image-plan-wrapper">
								<div class="floor-image-icon">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="27"
										height="27"
										viewBox="0 0 27 27"
										fill="none"
									>
										<path
											opacity="0.2"
											d="M23.625 5.90625V18.7798L19.4358 14.5905C19.2776 14.4324 19.063 14.3436 18.8394 14.3436C18.6157 14.3436 18.4011 14.4324 18.2429 14.5905L15.5366 17.2969L10.3001 12.0593C10.2217 11.9808 10.1287 11.9186 10.0262 11.8762C9.9238 11.8337 9.81401 11.8118 9.70312 11.8118C9.59224 11.8118 9.48245 11.8337 9.38002 11.8762C9.27759 11.9186 9.18453 11.9808 9.10617 12.0593L3.375 17.7915V5.90625C3.375 5.68247 3.4639 5.46786 3.62213 5.30963C3.78036 5.1514 3.99497 5.0625 4.21875 5.0625H22.7812C23.005 5.0625 23.2196 5.1514 23.3779 5.30963C23.5361 5.46786 23.625 5.68247 23.625 5.90625Z"
											fill="#B3B3B3"
										/>
										<path
											d="M22.7812 4.21875H4.21875C3.7712 4.21875 3.34197 4.39654 3.02551 4.71301C2.70904 5.02947 2.53125 5.4587 2.53125 5.90625V21.0938C2.53125 21.5413 2.70904 21.9705 3.02551 22.287C3.34197 22.6035 3.7712 22.7812 4.21875 22.7812H22.7812C23.2288 22.7812 23.658 22.6035 23.9745 22.287C24.291 21.9705 24.4688 21.5413 24.4688 21.0938V5.90625C24.4688 5.4587 24.291 5.02947 23.9745 4.71301C23.658 4.39654 23.2288 4.21875 22.7812 4.21875ZM22.7812 5.90625V16.7432L20.0317 13.9946C19.875 13.8379 19.6889 13.7136 19.4842 13.6287C19.2794 13.5439 19.0599 13.5003 18.8383 13.5003C18.6167 13.5003 18.3972 13.5439 18.1924 13.6287C17.9877 13.7136 17.8016 13.8379 17.6449 13.9946L15.5355 16.104L10.8949 11.4634C10.5785 11.1472 10.1494 10.9695 9.70207 10.9695C9.25471 10.9695 8.82565 11.1472 8.50922 11.4634L4.21875 15.7539V5.90625H22.7812ZM4.21875 18.1406L9.70312 12.6562L18.1406 21.0938H4.21875V18.1406ZM22.7812 21.0938H20.5274L16.7305 17.2969L18.8399 15.1875L22.7812 19.1299V21.0938ZM15.1875 10.5469C15.1875 10.2966 15.2617 10.0519 15.4008 9.84373C15.5399 9.6356 15.7375 9.47338 15.9688 9.37759C16.2001 9.2818 16.4545 9.25673 16.7 9.30557C16.9455 9.3544 17.1711 9.47494 17.3481 9.65194C17.5251 9.82894 17.6456 10.0545 17.6944 10.3C17.7433 10.5455 17.7182 10.7999 17.6224 11.0312C17.5266 11.2625 17.3644 11.4601 17.1563 11.5992C16.9481 11.7383 16.7034 11.8125 16.4531 11.8125C16.1175 11.8125 15.7955 11.6792 15.5582 11.4418C15.3208 11.2045 15.1875 10.8825 15.1875 10.5469Z"
											fill="#B3B3B3"
										/>
									</svg>
								</div>
								<div class="floor-image-input-wrapper">
									<label :for="`imageInput${floor.uuid}`">
										{{
											floor.floorImage
												? floor.floorImage
												: "Add Floor Plan"
										}}
									</label>
									<input
										:id="`imageInput${floor.uuid}`"
										type="file"
										@change="
											hanldeFloorImageInput(
												$event,
												floor.uuid
											)
										"
									/>
									<svg
										v-if="floor.floorImage"
										xmlns="http://www.w3.org/2000/svg"
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										class="remove-floor-image"
										@click="removeFloorImage(floor.uuid)"
									>
										<g clip-path="url(#clip0_436_3068)">
											<path
												d="M5 0.9375C2.76 0.9375 0.9375 2.76 0.9375 5C0.9375 7.24 2.76 9.0625 5 9.0625C7.24 9.0625 9.0625 7.24 9.0625 5C9.0625 2.76 7.24 0.9375 5 0.9375ZM5 1.5625C6.90219 1.5625 8.4375 3.09781 8.4375 5C8.4375 6.90219 6.90219 8.4375 5 8.4375C3.09781 8.4375 1.5625 6.90219 1.5625 5C1.5625 3.09781 3.09781 1.5625 5 1.5625ZM3.81875 3.36875L3.36875 3.81875L4.55125 5L3.36937 6.18125L3.81938 6.63125L5 5.44906L6.18125 6.63031L6.63125 6.18125L5.44906 5L6.63031 3.81875L6.18125 3.36875L5 4.55125L3.81875 3.36937V3.36875Z"
												fill="#EF4444"
											/>
										</g>
										<defs>
											<clipPath id="clip0_436_3068">
												<rect
													width="10"
													height="10"
													fill="white"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
								<div class="image-type-error">
									Allowed File Types: JPG, PNG, PDF
								</div>
							</div> -->

							<v-col>
								<v-btn
									dark
									color="#0062FF"
									elevation="0"
									@click="
										handleLevelPLus(
											floor,
											projectDetails.floorList.indexOf(
												floor
											)
										)
									"
								>
									LEVEL
									<v-icon dark> mdi-plus </v-icon>
									<v-icon right dark>
										mdi-arrow-up-box
									</v-icon>
								</v-btn>
								<v-btn
									dark
									color="#0062FF"
									elevation="0"
									@click="
										handleLevelMinus(
											floor,
											projectDetails.floorList.indexOf(
												floor
											)
										)
									"
								>
									LEVEL
									<v-icon dark> mdi-minus </v-icon>
									<v-icon right dark>
										mdi-arrow-down-box
									</v-icon>
								</v-btn>
								<v-btn
									v-if="
										floorListLength > 1 &&
										(projectDetails.floorList.indexOf(
											floor
										) == 0 ||
											projectDetails.floorList.indexOf(
												floor
											) ==
												floorListLength - 1)
									"
									dark
									color="#D73333"
									elevation="0"
									@click="handleRemoveFloor(floor)"
								>
									<v-icon dark>
										mdi-trash-can-outline
									</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</template>
					<v-form v-else ref="floorSetupForm" lazy-validation>
						<v-row dense align="center">
							<v-col class="level-name">
								<v-text-field
									v-model="floorSetupInitialData.floorName"
									outlined
									dense
									:rules="notEmptyRule"
									hide-details
									placeholder="Floor Name*"
									label="Floor Name*"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<v-col class="level-height">
								<v-text-field
									v-model="
										floorSetupInitialData.floorBaseLevel
									"
									outlined
									dense
									hide-details
									type="number"
									placeholder="Floor Base Level"
									label="Floor Base Level"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<v-col class="elevation-base">
								<v-text-field
									v-model="floorSetupInitialData.floorHeight"
									outlined
									dense
									hide-details
									type="number"
									:rules="notEmptyRule"
									placeholder="Floor Height*"
									label="Floor Height*"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<!-- <div class="floor-image-plan-wrapper">
								<div class="floor-image-icon">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="27"
										height="27"
										viewBox="0 0 27 27"
										fill="none"
									>
										<path
											opacity="0.2"
											d="M23.625 5.90625V18.7798L19.4358 14.5905C19.2776 14.4324 19.063 14.3436 18.8394 14.3436C18.6157 14.3436 18.4011 14.4324 18.2429 14.5905L15.5366 17.2969L10.3001 12.0593C10.2217 11.9808 10.1287 11.9186 10.0262 11.8762C9.9238 11.8337 9.81401 11.8118 9.70312 11.8118C9.59224 11.8118 9.48245 11.8337 9.38002 11.8762C9.27759 11.9186 9.18453 11.9808 9.10617 12.0593L3.375 17.7915V5.90625C3.375 5.68247 3.4639 5.46786 3.62213 5.30963C3.78036 5.1514 3.99497 5.0625 4.21875 5.0625H22.7812C23.005 5.0625 23.2196 5.1514 23.3779 5.30963C23.5361 5.46786 23.625 5.68247 23.625 5.90625Z"
											fill="#B3B3B3"
										/>
										<path
											d="M22.7812 4.21875H4.21875C3.7712 4.21875 3.34197 4.39654 3.02551 4.71301C2.70904 5.02947 2.53125 5.4587 2.53125 5.90625V21.0938C2.53125 21.5413 2.70904 21.9705 3.02551 22.287C3.34197 22.6035 3.7712 22.7812 4.21875 22.7812H22.7812C23.2288 22.7812 23.658 22.6035 23.9745 22.287C24.291 21.9705 24.4688 21.5413 24.4688 21.0938V5.90625C24.4688 5.4587 24.291 5.02947 23.9745 4.71301C23.658 4.39654 23.2288 4.21875 22.7812 4.21875ZM22.7812 5.90625V16.7432L20.0317 13.9946C19.875 13.8379 19.6889 13.7136 19.4842 13.6287C19.2794 13.5439 19.0599 13.5003 18.8383 13.5003C18.6167 13.5003 18.3972 13.5439 18.1924 13.6287C17.9877 13.7136 17.8016 13.8379 17.6449 13.9946L15.5355 16.104L10.8949 11.4634C10.5785 11.1472 10.1494 10.9695 9.70207 10.9695C9.25471 10.9695 8.82565 11.1472 8.50922 11.4634L4.21875 15.7539V5.90625H22.7812ZM4.21875 18.1406L9.70312 12.6562L18.1406 21.0938H4.21875V18.1406ZM22.7812 21.0938H20.5274L16.7305 17.2969L18.8399 15.1875L22.7812 19.1299V21.0938ZM15.1875 10.5469C15.1875 10.2966 15.2617 10.0519 15.4008 9.84373C15.5399 9.6356 15.7375 9.47338 15.9688 9.37759C16.2001 9.2818 16.4545 9.25673 16.7 9.30557C16.9455 9.3544 17.1711 9.47494 17.3481 9.65194C17.5251 9.82894 17.6456 10.0545 17.6944 10.3C17.7433 10.5455 17.7182 10.7999 17.6224 11.0312C17.5266 11.2625 17.3644 11.4601 17.1563 11.5992C16.9481 11.7383 16.7034 11.8125 16.4531 11.8125C16.1175 11.8125 15.7955 11.6792 15.5582 11.4418C15.3208 11.2045 15.1875 10.8825 15.1875 10.5469Z"
											fill="#B3B3B3"
										/>
									</svg>
								</div>
								<div class="floor-image-input-wrapper">
									<label
										for="initialImageInput"
										class="label"
										>{{
											floorSetupInitialData.floorImage
												? floorSetupInitialData.floorImage
												: "Add floor plan"
										}}</label
									>
									<input
										id="initialImageInput"
										type="file"
										@change="hanldeFloorImageInput($event)"
									/>
									<svg
										v-if="floorSetupInitialData.floorImage"
										xmlns="http://www.w3.org/2000/svg"
										width="10"
										height="10"
										viewBox="0 0 10 10"
										fill="none"
										class="remove-floor-image"
										@click="removeFloorImage()"
									>
										<g clip-path="url(#clip0_436_3068)">
											<path
												d="M5 0.9375C2.76 0.9375 0.9375 2.76 0.9375 5C0.9375 7.24 2.76 9.0625 5 9.0625C7.24 9.0625 9.0625 7.24 9.0625 5C9.0625 2.76 7.24 0.9375 5 0.9375ZM5 1.5625C6.90219 1.5625 8.4375 3.09781 8.4375 5C8.4375 6.90219 6.90219 8.4375 5 8.4375C3.09781 8.4375 1.5625 6.90219 1.5625 5C1.5625 3.09781 3.09781 1.5625 5 1.5625ZM3.81875 3.36875L3.36875 3.81875L4.55125 5L3.36937 6.18125L3.81938 6.63125L5 5.44906L6.18125 6.63031L6.63125 6.18125L5.44906 5L6.63031 3.81875L6.18125 3.36875L5 4.55125L3.81875 3.36937V3.36875Z"
												fill="#EF4444"
											/>
										</g>
										<defs>
											<clipPath id="clip0_436_3068">
												<rect
													width="10"
													height="10"
													fill="white"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
								<div class="image-type-error">
									Allowed File Types: JPG, PNG, PDF
								</div>
							</div> -->

							<v-col>
								<v-btn
									dark
									color="#0062FF"
									elevation="0"
									@click="hanldeCreateFloorCLick"
								>
									CREATE
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</div>
			<div v-if="selectedUnit" class="piping-setup-wrapper">
				<div class="piping-setup-label label">Piping Setup:</div>
				<div class="piping-setup">
					<v-row
						v-for="pipingSetup in projectDetails.pipingSetup"
						:key="pipingSetup.uuid"
						align="center"
						class="piping-setup-row"
						dense
					>
						<v-col class="system-name">
							<v-text-field
								v-model="pipingSetup.systemName"
								outlined
								hide-details
								dense
								placeholder="Enter Piping System Name*"
								label="Enter Piping System Name*"
								color="#007fff"
								@focus="
									handleFocusOnPipingSetup(
										'systemName',
										pipingSetup.uuid,
										pipingSetup.systemName
									)
								"
								@blur="onBLurUpdateProjectDetails()"
							>
							</v-text-field>
						</v-col>
						<v-col class="system-color">
							<v-menu
								:close-on-content-click="true"
								:offset-y="true"
							>
								<!-- :value="
								(() => {
									const colorObj =
										pipeColors.find(
											(obj) =>
												obj.hex ==
												pipingSetup.systemColor
										);
									if (!colorObj) return;
									return colorObj.text;
								})()
							" -->
								<template #activator="{ on }">
									<v-text-field
										dense
										readonly
										:value="
											colorName(pipingSetup.systemColor)
										"
										outlined
										:rules="notEmptyRule"
										hide-details
										placeholder="Select System Color*"
										label="Select System Color*"
										color="#007fff"
										v-on="on"
									>
										<template v-slot:append>
											<div
												v-if="pipingSetup.systemColor"
												class="color-tile"
												:style="`background:${pipingSetup.systemColor}`"
											>
												&nbsp;
											</div>
										</template>
									</v-text-field>
								</template>

								<div class="colors-container">
									<div
										class="colors"
										:class="
											color.hex == pipingSetup.systemColor
												? 'selected-cube'
												: ''
										"
										v-for="color in pipeColors"
										:key="color.text"
										:style="`background:${color.hex}`"
										@click="
											handlePipeSetuoColorSelectiion(
												color,
												pipingSetup.uuid
											)
										"
									>
										&nbsp;
									</div>
								</div>
							</v-menu>
						</v-col>
						<!-- <v-col class="system-color">
							<v-text-field
								v-model="pipingSetup.systemColor"
								outlined
								hide-details
								dense
								placeholder="Select Piping System Color*"
								label="Select Piping System Color*"
								color="#007fff"
								@focus="
									handleFocusOnPipingSetup(
										'systemColor',
										pipingSetup.uuid,
										pipingSetup.systemColor
									)
								"
								@blur="onBLurUpdateProjectDetails()"
							>
							</v-text-field>
						</v-col> -->
						<!-- <v-col class="pipe-thickness">
							<v-text-field
								v-model="pipingSetup.pipeThickness"
								:disabled="true"
								outlined
								hide-details
								dense
								placeholder="Select Pipe Thickness*"
								label="Select Pipe Thickness*"
								color="#007fff"
								@focus="
									handleFocusOnPipingSetup(
										'pipeThickness',
										pipingSetup.uuid,
										pipingSetup.pipeThickness
									)
								"
								@blur="onBLurUpdateProjectDetails()"
							>
								>
							</v-text-field>
						</v-col> -->
						<v-col class="pipe-size">
							<v-autocomplete
								v-model="pipingSetup.pipeSize"
								outlined
								dense
								hide-details
								placeholder="Select Pipe Size*"
								label="Select Pipe Size*"
								color="#007fff"
								:items="pipingSizes"
								item-text="size"
								item-value="uuid"
								multiple
								@focus="
									handleFocusOnPipingSetup(
										'pipeSize',
										pipingSetup.uuid,
										pipingSetup.pipeSize
									)
								"
								@blur="onBLurUpdateProjectDetails()"
							>
							</v-autocomplete>
						</v-col>
						<v-col>
							<v-btn
								dark
								color="#D73333"
								elevation="0"
								@click="handlePipeSetupDelete(pipingSetup)"
							>
								<v-icon dark> mdi-trash-can-outline </v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-form ref="pipingSetupForm" lazy-validation>
						<v-row dense align="center" class="piping-setup-row">
							<v-col class="system-name">
								<v-text-field
									v-model="pipingSetupFormData.systemName"
									outlined
									dense
									:rules="notEmptyRule"
									hide-details
									placeholder="Enter Piping System Name*"
									label="Enter Piping System Name*"
									color="#007fff"
								>
								</v-text-field>
							</v-col>
							<v-col class="system-color">
								<v-menu
									:close-on-content-click="true"
									:offset-y="true"
								>
									<template #activator="{ on }">
										<v-text-field
											:value="
												colorName(
													pipingSetupFormData.systemColor
												)
											"
											dense
											:readonly="true"
											outlined
											:rules="notEmptyRule"
											hide-details
											placeholder="Select System Color*"
											label="Select System Color*"
											color="#007fff"
											v-on="on"
										>
											<template v-slot:append>
												<div
													v-if="
														pipingSetupFormData.systemColor
													"
													class="color-tile"
													:style="`background:${pipingSetupFormData.systemColor}`"
												>
													&nbsp;
												</div>
											</template>
										</v-text-field>
									</template>

									<div class="colors-container">
										<div
											class="colors"
											v-for="color in pipeColors"
											:key="color.text"
											:style="`background:${color.hex}`"
											@click="
												handleNewPipeColorSelectiion(
													color
												)
											"
										>
											&nbsp;
										</div>
									</div>
								</v-menu>
							</v-col>
							<!-- <v-col class="pipe-thickness">
								<v-text-field
									:disabled="true"
									:value="1"
									outlined
									dense
									:rules="notEmptyRule"
									hide-details
									placeholder="Select Pipe Thickness*"
									label="Select Pipe Thickness*"
									color="#007fff"
								>
								</v-text-field>
							</v-col> -->
							<v-col class="pipe-size">
								<v-autocomplete
									v-model="pipingSetupFormData.pipeSize"
									:rules="notEmptyArrayRule"
									outlined
									dense
									hide-details
									placeholder="Select Pipe Size*"
									label="Select Pipe Size*"
									color="#007fff"
									:items="pipingSizes"
									item-text="size"
									item-value="uuid"
									return-object
									multiple
								>
								</v-autocomplete>
							</v-col>
							<v-col>
								<v-btn
									dark
									color="#0062FF"
									elevation="0"
									@click="handleAddPipingSetupClick()"
								>
									ADD
									<v-icon right dark> mdi-plus </v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</div>
		</div>
		<div class="open-project-fab-btn">
			<v-btn
				dark
				color="#0062FF"
				elevation="0"
				:disabled="!disableGoToPRoject"
				@click="handleOpenProjectInEditor"
			>
				Go To Project
				<v-icon right dark> mdi-arrow-up-box </v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { putAPIResponse } from "@/network/base/api";

export default {
	components: {},
	data: () => ({
		projectId: "",
		selectedUnit: null,
		pipeThickness: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		pipingSetupFormData: {},
		frozenFloors: [],
		floorSetupInitialData: {},
		// v.length >= 10
		notEmptyRule: [(v) => !!v || "Field is required"],
		notEmptyArrayRule: [(v) => (v && v.length > 0) || "Field is required"],
		primaryUnits: [],
		pipingSizes: [],
		projectDetails: null,
		pipingFieldEditing: {},
		pipeColors: [
			{ hex: "#FFFF00", text: "Yellow" },
			{ hex: "#00FF00", text: "Lime" },
			{ hex: "#00FFFF", text: "Cyan" },
			{ hex: "#FF00FF", text: "Magenta" },
			{ hex: "#0000FF", text: "Blue" },
			{ hex: "#FF0000", text: "Red" },
			{ hex: "#000080", text: "Navy" },
			{ hex: "#008080", text: "Teal" },
			{ hex: "#008000", text: "Green" },
			{ hex: "#800080", text: "Purple" },
			{ hex: "#800000", text: "Maroon" },
			{ hex: "#808000", text: "Olive" },
			{ hex: "#808080", text: "Gray" },
			{ hex: "#C0C0C0", text: "Silver" },
			{ hex: "#000000", text: "Black" },
		],
	}),
	computed: {
		floorListLength() {
			return this.projectDetails.floorList
				? this.projectDetails.floorList.length
				: 0;
		},
		disableSelectingunits() {
			if (!this.projectDetails) return false;
			if (
				this.projectDetails.floorList.length > 0 ||
				this.projectDetails.pipingSetup.length > 0
			) {
				return true;
			}
			return false;
		},
		disableGoToPRoject() {
			if (!this.projectDetails) return false;
			if (
				this.projectDetails.floorList.length > 0 &&
				this.projectDetails.pipingSetup.length > 0
			) {
				return true;
			}
			return false;
		},
		metricUnits() {
			if (this.primaryUnits.length > 0) {
				return this.primaryUnits.filter(
					(unit) => unit.type == "metric"
				);
			}
			return [];
		},
		imperialUnits() {
			if (this.primaryUnits.length > 0) {
				return this.primaryUnits.filter(
					(unit) => unit.type == "imperial" && unit.shorthand == "ft"
				);
			}
			return [];
		},
		// pipingFormSystemColorName() {
		// 	try {
		// 		const colorName = this.pipeColors.find(
		// 			(obj) => obj.hex == this.pipingSetupFormData.systemColor
		// 		).text;
		// 		return colorName;
		// 	} catch (e) {
		// 		return "";
		// 	}
		// },
	},
	async mounted() {
		this.projectId = this.$route.params.id;
		await this.getPrimaryUnits();
		await this.getPipingSizes();
		this.fetchProjectDetails({ _id: this.projectId }).then((res) => {
			this.closeOverlayLoader();
			this.projectDetails = res.data.data;
			this.projectDetails["settings"] = {
				snapSettings: {
					enabled: true,
				},
				gridSettings: {
					spacing: 1,
					color: "#000000",
					enabled: true,
					type: "line",
				},
				anchorSettings: {
					showAnchor: 1,
					color: "#007fff",
					enabled: true,
				},
				imageSettings: {
					showImage: true,
					enabled: true,
					transparency: 1,
					lock: false,
				},
				orthogonalSettings: {
					enabled: true,
					angles: [],
				},
			};
			this.updateProject("settings");
			if (this.projectDetails.primaryUnits) {
				const slectedUnit = this.primaryUnits.find((unit) => {
					return (
						unit.shorthand == this.projectDetails.primaryUnits.uom
					);
				});
				console.log(slectedUnit, this.primaryUnits);
				this.handlePrimaryUnitsClick(slectedUnit);
			}
		});
	},
	destroyed() {},
	methods: {
		...mapActions("project", [
			"fetchProjectDetails",
			"updateProjectDetails",
			"uploadProjectFloorImage",
			"deleteProjectFloorImage",
			"fetchPrimaryUnits",
			"fetchPipingSizes",
		]),
		...mapMutations("uiManager", ["openSnackbar", "closeOverlayLoader"]),
		async getPipingSizes(search = "") {
			const pipingSizes = await this.fetchPipingSizes({ search });
			this.pipingSizes = pipingSizes.data;
			this.closeOverlayLoader();
		},
		async getPrimaryUnits() {
			const primaryUnits = await this.fetchPrimaryUnits();
			this.primaryUnits = primaryUnits.data;
			this.closeOverlayLoader();
		},
		async handlePrimaryUnitsClick(unit) {
			this.selectedUnit = unit;
			this.projectDetails.primaryUnits = {
				type: unit.type,
				uom: unit.shorthand,
			};
			this.updateProject("primaryUnits", false);
			await this.getPipingSizes(unit.type);
			const shorthandToFilter = unit.type == "metric" ? "mm" : "in";
			console.log(shorthandToFilter, "shorthand to filter");
			const filterSizes = this.pipingSizes.filter((size) => {
				return size.unitData.shorthand == shorthandToFilter;
			});
			console.log(filterSizes, unit);
			this.pipingSizes = filterSizes;
		},
		extractFileData(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();

				reader.onload = (event) => {
					const fileData = event.target.result;

					const extractedData = {
						name: file.name,
						size: file.size,
						type: file.type,
						data: fileData,
					};

					resolve(extractedData);
				};

				reader.onerror = (event) => {
					reject(event.target.error);
				};

				reader.readAsDataURL(file);
			});
		},
		// checking for jpeg,png and pdf extensions
		checkFileExtension(fileObj) {
			if (!fileObj) return true;
			const fileExtension = fileObj.name.split(".").pop().toLowerCase();
			if (
				fileExtension === "jpeg" ||
				fileExtension === "jpg" ||
				fileExtension === "png" ||
				fileExtension === "pdf"
			) {
				return true;
			}
			return false;
		},
		async hanldeFloorImageInput(e, id = "") {
			const file = e.target.files[0];
			const fileData = await this.extractFileData(file);
			const checkExtension = this.checkFileExtension(fileData);
			console.log(checkExtension);
			if (!checkExtension) {
				this.openSnackbar({
					text: "Inavlid File, only png,jpeg,jpg and pdfs are supported",
					type: "error",
				});
				return;
			}
			let formData = new FormData();
			formData.append("image", file);
			const response = await this.uploadProjectFloorImage(formData);
			this.closeOverlayLoader();
			if (!response.data.success) return;
			if (id == "") {
				this.floorSetupInitialData.floorImage = response.data.data;
			} else {
				const floor = this.projectDetails.floorList.find(
					(floor) => floor.uuid == id
				);

				floor.floorImage = response.data.data;
				this.updateProject("floorList");
			}
		},
		async removeFloorImage(id = "") {
			if (id == "") {
				this.floorSetupInitialData.floorImage = null;
				return;
			}
			const floor = this.projectDetails.floorList.find(
				(floor) => floor.uuid == id
			);
			const deleteProjectFloorImageResponse =
				await this.deleteProjectFloorImage({ key: floor.floorImage });
			this.closeOverlayLoader();
			if (!deleteProjectFloorImageResponse.data.success) return;
			floor.floorImage = null;
			this.updateProject("floorList");
		},
		handlePipeSetupDelete(pipingSetup) {
			const deleteConfirmation = confirm(
				"Are you sure you want to delete this pipe?"
			);
			if (!deleteConfirmation) return;
			const pipingSetupData = JSON.parse(
				JSON.stringify(this.projectDetails.pipingSetup)
			);
			let newPipingSetup = pipingSetupData.filter(
				(pipingSetupObj) => pipingSetupObj.uuid != pipingSetup.uuid
			);
			this.projectDetails.pipingSetup = newPipingSetup;
			this.updateProject("pipingSetup");
		},
		handleAddPipingSetupClick() {
			if (!this.$refs["pipingSetupForm"].validate()) {
				this.openSnackbar({
					text: "All Fields are required",
					type: "error",
				});
				return;
			}
			const pipingSetupData = JSON.parse(
				JSON.stringify(this.pipingSetupFormData)
			);
			// let selectedPipeSizes = [];
			// this.pipingSizes.forEach((size) => {
			// 	if (pipingSetupData.pipeSize.includes(size._id)) {
			// 		selectedPipeSizes.push(size.size);
			// 	}
			// });
			// pipingSetupData.pipeSize = selectedPipeSizes;
			console.log(pipingSetupData);
			this.projectDetails.pipingSetup.push({
				...pipingSetupData,
				pipeThickness: 1,
				uuid: uuidv4(),
			});
			this.pipingSetupFormData = {};
			this.$refs["pipingSetupForm"].resetValidation();
			this.updateProject("pipingSetup");
		},
		hanldeCreateFloorCLick() {
			console.log(this.$refs["floorSetupForm"].validate());
			if (!this.$refs["floorSetupForm"].validate()) {
				this.openSnackbar({
					text: "Name and Height are required",
					type: "error",
				});
				return;
			}
			const data = JSON.parse(
				JSON.stringify({
					uuid: Date.now().toString(),
					levelName: this.floorSetupInitialData.floorName,
					levelHeight: Number(this.floorSetupInitialData.floorHeight),
					elevationBase:
						Number(this.floorSetupInitialData.floorBaseLevel) || 0,
					elevationTop:
						Number(this.floorSetupInitialData.floorBaseLevel) ||
						0 + Number(this.floorSetupInitialData.floorHeight),
				})
			);
			this.projectDetails.floorList.push({
				...data,
				floorImage: this.floorSetupInitialData.floorImage
					? this.floorSetupInitialData.floorImage
					: null,
				uuid: uuidv4(),
			});
			this.$nextTick(() => {
				this.updateProject("floorList");
			});
		},
		handleLevelPLus(floor, index) {
			let data = JSON.parse(JSON.stringify(floor));
			let newFLoor = {
				uuid: Date.now().toString(),
				levelName: "Floor Name",
				elevationBase: Number(data.elevationTop),
				levelHeight: Number(data.levelHeight),
				floorImage: null,
			};
			newFLoor = {
				...newFLoor,
				elevationTop: Number(
					newFLoor.elevationBase + newFLoor.levelHeight
				),
			};
			if (index == 0) {
				this.projectDetails.floorList.unshift({
					...newFLoor,
					uuid: uuidv4(),
				});
				this.handleHeightChange();
				return;
			}
			this.projectDetails.floorList.splice(index, 0, {
				...newFLoor,
				uuid: uuidv4(),
			});
			this.handleHeightChange();
		},
		handleLevelMinus(floor, index) {
			let data = JSON.parse(JSON.stringify(floor));
			let newFLoor = {
				uuid: Date.now().toString(),
				levelName: "Floor Name",
				elevationTop: Number(data.elevationBase),
				levelHeight: Number(data.levelHeight),
				floorImage: null,
			};
			newFLoor = {
				...newFLoor,
				elevationBase: Number(
					newFLoor.elevationTop - newFLoor.levelHeight
				),
			};
			if (index == this.floorListLength - 1) {
				this.projectDetails.floorList.push({
					...newFLoor,
					uuid: uuidv4(),
				});
				this.handleHeightChange();
				return;
			}
			this.projectDetails.floorList.splice(index + 1, 0, {
				...newFLoor,
				uuid: uuidv4(),
			});
			this.handleHeightChange();
		},
		handleHeightChange() {
			if (
				!this.projectDetails.floorList[this.floorListLength - 1]
					.levelHeight
			) {
				this.projectDetails.floorList[
					this.floorListLength - 1
				].levelHeight = 0;
			}
			this.projectDetails.floorList[
				this.floorListLength - 1
			].elevationTop =
				Number(
					this.projectDetails.floorList[this.floorListLength - 1]
						.elevationBase
				) +
				Number(
					this.projectDetails.floorList[this.floorListLength - 1]
						.levelHeight
				);
			for (let i = this.floorListLength - 2; i >= 0; i--) {
				if (!this.projectDetails.floorList[i].levelHeight) {
					this.projectDetails.floorList[i].levelHeight = 0;
				}
				this.projectDetails.floorList[i].elevationBase = Number(
					this.projectDetails.floorList[i + 1].elevationTop
				);
				this.projectDetails.floorList[i].elevationTop =
					Number(this.projectDetails.floorList[i].elevationBase) +
					Number(this.projectDetails.floorList[i].levelHeight);
			}
			this.updateProject("floorList");
		},
		updateProject(keyToUpdate, showToast = true) {
			this.updateProjectDetails({
				payload: { [keyToUpdate]: this.projectDetails[keyToUpdate] },
				_id: this.projectId,
				showToast,
			}).then((res) => {
				this.closeOverlayLoader();
				console.log(res);
			});
		},
		handleRemoveFloor(floor) {
			const deleteConfirmation = confirm(
				"Are you sure you want to delete this floor?"
			);
			if (!deleteConfirmation) return;
			const floorList = JSON.parse(
				JSON.stringify(this.projectDetails.floorList)
			);
			let newFLoorList = floorList.filter(
				(floorObj) => floorObj.uuid != floor.uuid
			);
			this.projectDetails.floorList = newFLoorList;
			this.updateProject("floorList");
		},
		handleColorInputChnages(e, id = null) {
			if (!id) this.pipingSetupFormData.systemColor = e.hexa;
		},
		handleFocusOnPipingSetup(key, uuid, value) {
			this.pipingFieldEditing = {
				key: JSON.parse(JSON.stringify(key)),
				value: JSON.parse(JSON.stringify(value)),
				uuid: JSON.parse(JSON.stringify(uuid)),
			};
		},
		onBLurUpdateProjectDetails() {
			if (!this.pipingFieldEditing.uuid) return;
			const pipeEdited = this.projectDetails.pipingSetup.find(
				(pipe) => pipe.uuid == this.pipingFieldEditing.uuid
			);
			console.log(!!pipeEdited[this.pipingFieldEditing.key]);
			console.log(pipeEdited[this.pipingFieldEditing.key]);
			if (
				pipeEdited[this.pipingFieldEditing.key] ==
				this.pipingFieldEditing.value
			) {
				this.pipingFieldEditing = {};
				return;
			}
			if (
				!pipeEdited[this.pipingFieldEditing.key] ||
				!pipeEdited[this.pipingFieldEditing.key].length ||
				pipeEdited[this.pipingFieldEditing.key].length <= 0
			) {
				this.openSnackbar({
					text: `Failed to update ${this.pipingFieldEditing.key}, field cannot be empty`,
					type: "error",
				});
				pipeEdited[this.pipingFieldEditing.key] =
					this.pipingFieldEditing.value;
			} else {
				this.updateProject("pipingSetup");
			}
			this.pipingFieldEditing = {};
		},
		handleOpenProjectInEditor() {
			window.open(
				`${location.origin}/editor?project=${this.projectId}`,
				"_blank"
			);
		},
		handleNewPipeColorSelectiion(color) {
			this.pipingSetupFormData.systemColor = color.hex;
		},
		handlePipeSetuoColorSelectiion(color, id) {
			const pipe = this.projectDetails.pipingSetup.find(
				(pipe) => pipe.uuid == id
			);
			pipe.systemColor = color.hex;
			this.updateProject("pipingSetup");
		},
		colorName(colorHex) {
			const colorObj = this.pipeColors.find((obj) => obj.hex == colorHex);
			if (!colorObj) return "";
			return colorObj.text;
		},
	},
};
</script>

<style lang="scss" scoped>
.project-setup-wrapper {
	margin-top: 1.875rem;
	height: calc(100% - 2.3125rem);
	background-color: $primary-white;
	.header {
		width: 100%;
		margin-bottom: 2.3125rem;
		.header-text {
			height: 5.625rem;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #292f38;
			font-size: 1.5625rem;
			font-weight: 500;
			letter-spacing: 0.0625rem;
		}
		.project-details-wrapper {
			height: 5.625rem;
			background: #edf4ff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.project-detail {
				display: flex;
				text-align: center;
				padding: 0 4rem;
				&-label {
					color: #475569;
					font-size: 0.9956rem;
					font-weight: 600;
					line-height: 1.477rem;
					text-transform: uppercase;
					margin-right: 0.5rem;
				}
				&-value {
					color: #0000008f;
					font-size: 0.9956rem;
					font-weight: 500;
					letter-spacing: 0.0069rem;
				}
			}
		}
	}
	.project-settings-wrapper {
		padding: 0 4rem;
		.label {
			color: #475569;
			font-size: 1.0339rem;
			font-weight: 600;
			text-transform: uppercase;
		}
		.select-units-wrapper {
			display: flex;
			.select-units-options-wrapper {
				display: flex;
				flex-direction: column;
				padding: 0 1.25rem;
				position: relative;
				.disble-units {
					pointer-events: none;
					.select-units-options-value {
						opacity: 0.7;
					}
				}
				.select-units-options {
					display: flex;
					margin-bottom: 0.5625rem;
					.select-units-options-value {
						cursor: pointer;
						padding: 0.1875rem 0.875rem;
						border-radius: 0.125rem;
						border: 0.0625rem solid $primary-blue;
						color: $primary-blue;
						font-size: 0.7212rem;
						font-weight: 500;
						line-height: 1.477rem;
						&--imperial {
							margin-right: 0.8125rem;
						}
						&--metric {
							margin-right: 0.5625rem;
						}
						&.selected {
							background-color: rgba($primary-blue, 0.1);
						}
					}
					.selected-units-hint {
						padding: 0.375rem 0.4375rem;
						border-radius: 0.125rem;
						background: rgba(42, 199, 31, 0.08);
						color: #0cbf00;
						text-align: center;
						font-size: 0.625rem;
						font-weight: 500;
						line-height: 1.125rem;
						letter-spacing: 0.0187rem;
					}
				}
				.select-units-warning {
					display: flex;
					align-items: center;
					top: -1.375rem;
					position: absolute;
					.warning-icon {
						height: 1.125rem;
						width: 1.125rem;
						margin-right: 0.25rem;
					}
					.warning-text {
						width: max-content;
						color: #d7a648;
						font-size: 0.625rem;
						font-weight: 500;
						line-height: 1.125rem;
						letter-spacing: 0.0187rem;
					}
				}
			}
		}
		.piping-setup-wrapper {
			margin-bottom: 2.5rem;
			.piping-setup-label {
				margin-bottom: 1.3125rem;
			}
			.piping-setup {
				margin-left: 0.125rem;
				&-row {
					margin-bottom: 0.2 5rem;
				}

				.system-color {
					max-width: 13.1rem;
					min-width: max-content;
					.color-picker {
						width: 100%;
						display: flex;
						border: 0.0625rem solid rgba(0, 0, 0, 0.3803921569);
						border-radius: 0.5rem;
						padding: 0.25rem 0.5rem;
						align-items: center;
						min-height: 2.5rem;
						color: rgba(0, 0, 0, 0.6);
						font-size: 0.75rem;
						font-weight: 400;
						text-align: center;
					}
				}
				.system-name {
					max-width: 13rem;
				}

				.pipe-thickness {
					max-width: 11.3rem;
				}
				.pipe-size {
					max-width: 9.2rem;
				}
			}
		}
		.floor-setup-wrapper {
			margin-bottom: 1.875rem;
			.floor-setup-label {
				margin-bottom: 1.875rem;
			}
			.floor-setup {
				.floor-with-invalid-floor-image-extension {
					margin-bottom: 1.5rem;
					.floor-image-plan-wrapper {
						border: 1px solid #ef4444;
						.image-type-error {
							visibility: visible;
						}
					}
				}

				.level-name,
				.level-height {
					max-width: 9.4rem;
				}
				.elevation-base,
				.elevation-top {
					max-width: 8.1rem;
				}
				.v-btn {
					margin-right: 0.8125rem;
				}
				.floor-image-plan-wrapper {
					min-width: 10rem;
					display: flex;
					border: 0.0625rem solid #00000061;
					border-radius: 0.5rem;
					padding: 0.25rem;
					align-items: center;
					justify-content: center;
					min-height: 2.5rem;
					position: relative;
					.floor-image-icon {
						height: 1.5rem;
					}
					.floor-image-input-wrapper {
						display: flex;
						position: relative;
						align-items: center;
						border: 0.0625rem solid #cacaca;
						background: #e7e7e7;
						height: 1.5rem;
						padding: 0.3125rem 0.5rem;
						label {
							color: rgba(0, 0, 0, 0.39);
							font-size: 0.75rem;
							font-weight: 400;
							text-align: center;
							max-width: 5rem;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						input[type="file"] {
							display: none;
						}
						.remove-floor-image {
							// visibility: hidden;
							cursor: pointer;
							position: absolute;
							top: -0.4375rem;
							right: -0.75rem;
						}
					}
					.image-type-error {
						visibility: hidden;
						font-size: 0.75rem;
						color: rgba(0, 0, 0, 0.82);
						font-style: normal;
						font-weight: 400;
						width: max-content;
						display: flex;
						bottom: -22px;
						left: 0px;
						position: absolute;
					}
				}
			}
		}
	}
	.open-project-fab-btn {
		position: fixed;
		bottom: 2rem;
		right: 2rem;
	}
}
</style>

<style lang="scss">
.v-input,
.v-label,
.v-input::placeholder {
	color: #00000063;
	font-size: 0.75rem;
	font-weight: 400;
}
.v-text-field--outlined {
	border-radius: 0.5rem;
}
.v-select__selections {
	flex-wrap: nowrap;
}
.search-box {
	.v-text-field--outlined.v-input--dense.v-text-field--outlined {
		.v-input__control {
			.v-input__slot {
				min-height: 1.875rem;
			}
		}
	}
}
.v-menu__content {
	overflow: hidden;
}

.colors-container {
	background-color: $primary-white;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-columns: repeat(5, 1fr);
	gap: 0.5rem;
	padding: 0.5rem;
	.colors {
		height: 1.875rem;
		width: 1.875rem;
		border-radius: 0.25rem;
		cursor: pointer;
		&.selected-cube,
		&:hover {
			border: 1px solid #000;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
			transform: scale(1.1);
		}
	}
}
.color-tile {
	height: 1.25rem;
	width: 1.25rem;
	border-radius: 0.25rem;
	cursor: pointer;
}
</style>
