<template>
	<div class="project">
		<div class="project_list">
			<div class="project_list-header">
				<h3 v-if="!showDisabledProjects" class="primary-heading">
					Project List
				</h3>
				<h3 v-else class="primary-heading">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="11"
						height="20"
						viewBox="0 0 11 20"
						fill="none"
						class="arrow-svg"
						@click="handleShowDisabledProjects"
					>
						<path
							d="M2.41512 10L10.7068 18.291C10.8945 18.4787 11 18.7333 11 18.9989C11 19.2644 10.8945 19.519 10.7068 19.7068C10.5191 19.8945 10.2645 20 9.99901 20C9.73354 20 9.47893 19.8945 9.29121 19.7068L0.293706 10.7079C0.200605 10.615 0.12674 10.5047 0.0763414 10.3832C0.0259425 10.2617 0 10.1315 0 10C0 9.86848 0.0259425 9.73826 0.0763414 9.61678C0.12674 9.49531 0.200605 9.38497 0.293706 9.29209L9.29121 0.293226C9.47893 0.105476 9.73354 0 9.99901 0C10.2645 0 10.5191 0.105476 10.7068 0.293226C10.8945 0.480976 11 0.735619 11 1.00114C11 1.26665 10.8945 1.5213 10.7068 1.70905L2.41512 10Z"
							fill="black"
						/>
					</svg>
					<span> List of Deleted Project </span>
				</h3>

				<div class="project_list-header-container">
					<div class="project_list-header-container-items">
						<v-btn
							v-if="selectedProjects.length > 0"
							tile
							elevation="0"
							color="#c9302a"
							dark
							small
							@click="handleDeleteClick"
						>
							Delete Project
							<v-icon right> mdi-delete </v-icon></v-btn
						>
						<v-btn
							v-if="!showDisabledProjects"
							tile
							elevation="0"
							color="#0062FF"
							dark
							small
							@click="handlePopUp"
						>
							Create New Projects
							<v-icon right> mdi-plus </v-icon></v-btn
						>
						<v-btn
							v-if="!showDisabledProjects"
							tile
							icon
							dark
							elevation="0"
							class="action-icon"
							small
							@click="handleShowDisabledProjects"
						>
							<v-icon> mdi-recycle </v-icon></v-btn
						>
						<v-btn
							v-if="false"
							tile
							dark
							icon
							elevation="0"
							class="action-icon"
							small
						>
							<v-icon> mdi-menu </v-icon></v-btn
						>
						<!-- <div class="project_list-header-container-items-btn">
							<button class="header_btn" @click="handlePopUp">
								<span>Create New Projects</span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="26"
									height="29"
									viewBox="0 0 26 29"
									fill="none"
									class="header_btn-svg"
								>
									<path
										d="M11.9695 22.4652V15.6376H5.52557V13.3618H11.9695V6.53418H14.1174V13.3618H20.5613V15.6376H14.1174V22.4652H11.9695Z"
										fill="white"
									/>
								</svg>
							</button>
						</div>
						<div
							class="project_list-header-container-items-refreshBtn item-center"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="28"
								height="28"
								viewBox="0 0 28 28"
								fill="none"
								class="refresh-btn primary-svg"
							>
								<path
									d="M22.5216 14.0424L24.2649 17.0601C24.6145 17.6656 24.7985 18.3524 24.7985 19.0515C24.7985 19.7506 24.6145 20.4374 24.2649 21.0429C23.9154 21.6483 23.4126 22.1511 22.8072 22.5007C22.2017 22.8502 21.5149 23.0343 20.8158 23.0343H18.4683V25.8791L11.68786 21.8964L18.4683 17.9136V20.7584H20.8158C21.1154 20.7584 21.4098 20.6795 21.6693 20.5297C21.9287 20.3799 22.1442 20.1644 22.294 19.905C22.4438 19.6455 22.5227 19.3511 22.5227 19.0515C22.5227 18.7519 22.4438 18.4576 22.294 18.1981L20.5507 15.1803L22.5227 14.0424H22.5216ZM8.5751 10.6684L9.17821 17.587L6.71459 16.1646L5.54252 18.1969C5.39247 18.4564 5.31337 18.7507 5.31318 19.0504C5.31298 19.3501 5.39169 19.6446 5.5414 19.9042C5.69111 20.1638 5.90653 20.3794 6.166 20.5294C6.42548 20.6793 6.71986 20.7583 7.01955 20.7584H10.5039V23.0343H7.01955C6.32034 23.0345 5.63341 22.8506 5.02781 22.5011C4.42221 22.1516 3.91929 21.6488 3.5696 21.0434C3.21992 20.4379 3.0358 19.751 3.03576 19.0518C3.03571 18.3526 3.21974 17.6657 3.56934 17.0601L4.74255 15.0267L2.27893 13.6054L8.57397 10.6695L8.5751 10.6684ZM15.9102 3.65304C16.5152 4.00275 17.0175 4.50547 17.3668 5.11073L18.5411 7.14308L21.0047 5.72066L20.4005 12.6393L14.1066 9.70342L16.5702 8.28101L15.397 6.24866C15.2472 5.9892 15.0317 5.77375 14.7722 5.62395C14.5128 5.47415 14.2184 5.39529 13.9188 5.39529C13.6192 5.39529 13.3249 5.47415 13.0654 5.62395C12.8059 5.77375 12.5905 5.9892 12.4407 6.24866L10.6962 9.26873L8.72531 8.1308L10.4686 5.11187C10.9968 4.19745 11.8665 3.53021 12.8865 3.25685C13.9065 2.9835 14.9933 3.12642 15.9079 3.65418L15.9102 3.65304Z"
									fill="white"
								/>
							</svg>
						</div>
						<div
							class="project_list-header-container-items-menuBtn item-center"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="31"
								height="30"
								viewBox="0 0 31 30"
								fill="none"
								class="menu-icon primary-svg"
							>
								<path
									d="M5.56952 22.465H25.2937C25.9717 22.465 26.5264 21.9102 26.5264 21.2322C26.5264 20.5542 25.9717 19.9995 25.2937 19.9995H5.56952C4.8915 19.9995 4.33676 20.5542 4.33676 21.2322C4.33676 21.9102 4.8915 22.465 5.56952 22.465ZM5.56952 16.3012H25.2937C25.9717 16.3012 26.5264 15.7464 26.5264 15.0684C26.5264 14.3904 25.9717 13.8357 25.2937 13.8357H5.56952C4.8915 13.8357 4.33676 14.3904 4.33676 15.0684C4.33676 15.7464 4.8915 16.3012 5.56952 16.3012ZM4.33676 8.90463C4.33676 9.58265 4.8915 10.1374 5.56952 10.1374H25.2937C25.9717 10.1374 26.5264 9.58265 26.5264 8.90463C26.5264 8.22662 25.9717 7.67188 25.2937 7.67188H5.56952C4.8915 7.67188 4.33676 8.22662 4.33676 8.90463Z"
									fill="white"
								/>
							</svg>
						</div> -->
					</div>
					<div class="project_list-header-container-items search-box">
						<div class="project_list-header-container-items-label">
							Search:
						</div>
						<v-text-field
							v-model="searchText"
							type="text"
							dense
							color="#0062FF"
							outlined
							hide-details
							placeholder="Search Projects...."
							@input="handleProjectsSerach"
						>
						</v-text-field>
					</div>
				</div>
			</div>
		</div>
		<div class="project_table">
			<ListTable
				:userheaderdata="headerData"
				:userrowsdata="projectsList"
				:page="page"
				:limit="limit"
				:showDisabledProjects="showDisabledProjects"
				:totalProjects="totalProjects"
				@onPaginationChanges="handlePagination"
				@projectSelected="handleSelectedProjectListUpdates"
				@restoreProject="restoreDeletedProject"
			/>
		</div>
		<ProjectDetailsPopUp
			v-if="isShowPopup"
			@popupHide="hidePopup"
			@onCreateProjectClick="createProject"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import ProjectDetailsPopUp from "@/components/sharedComponents/ProjectDetailsPopUp.vue";
import ListTable from "@/components/sharedComponents/ListTable.vue";
// projectData

export default {
	components: {
		ProjectDetailsPopUp,
		ListTable,
	},

	data() {
		return {
			showDisabledProjects: false,
			isShowPopup: false,
			projectsList: [],
			headers: [
				{
					text: "Sr.No",
					align: "center",
					value: "srNo",
					sortable: false,
					class: "section-header",
				},
				{
					text: "Project Reference",
					align: "center",
					value: "projectReference",
					sortable: false,
					class: "section-header",
				},
				{
					text: "Project Name",
					align: "center",
					value: "name",
					sortable: false,
					class: "section-header",
				},
				{
					text: "Date Created",
					align: "center",
					value: "createdAt",
					sortable: false,
					class: "section-header",
				},
				{
					text: "Last Modified",
					align: "center",
					value: "updatedAt",
					sortable: false,
					class: "section-header",
				},
				{
					text: "Versions",
					align: "center",
					value: "version",
					sortable: false,
					class: "section-header",
				},
			],
			page: 1,
			totalProjects: 0,
			limit: 50,
			searchText: "",
			selectedProjects: [],
			searchTimeoutRef: null,
			filter: {},
		};
	},
	computed: {
		// ...mapGetters("project", []),
		// projectsToDisplay() {
		// 	if (!this.projectsList || !this.projectsList.length) return;
		// 	return this.projectsList.filter(
		// 		(project) => project.isDisabled == this.showDisabledProjects
		// 	);
		// },
		headerData() {
			if (!this.showDisabledProjects) {
				return [
					...this.headers,
					{
						text: "Project Link",
						align: "center",
						value: "link",
						sortable: false,
						class: "section-header",
					},
				];
			}
			return [
				...this.headers,
				{
					text: "Restore",
					align: "center",
					value: "restore",
					sortable: false,
					class: "section-header",
				},
			];
		},
	},
	async mounted() {
		this.filter = { isDisabled: false };
		this.getAllProjects();
	},
	destroyed() {},
	methods: {
		...mapMutations("uiManager", ["closeOverlayLoader"]),
		...mapActions("project", [
			"fetchAllProjects",
			"addProject",
			"removeProjects",
			"updateProjectDetails",
		]),
		async getAllProjects() {
			const payload = {
				page: this.page,
				limit: this.limit,
				search: this.searchText,
				filter: this.filter,
			};
			const projects = await this.fetchAllProjects(payload);
			this.totalProjects = projects.meta.totalItems;
			this.projectsList = projects.data;

			this.closeOverlayLoader();
		},

		handlePopUp() {
			this.isShowPopup = !this.isShowPopup;
		},
		hidePopup() {
			this.isShowPopup = false;
		},
		createProject(data) {
			this.addProject(data).then((res) => {
				console.log(res);
				this.closeOverlayLoader;
				this.getAllProjects();
				this.handlePopUp();
				this.hidePopup();
			});
		},
		handlePagination(data) {
						const { page, limit } = data;
			this.page = page;
			this.limit = limit == -1 ? this.totalProjects : limit;
			this.getAllProjects();
		},
		async handleDisablingProjects() {
			if (!this.selectedProjects.length) return;
			const deleteConfirmation = confirm(
				"Are you sure you want to delete the selected projects?"
			);

			if (!deleteConfirmation) return;

			try {
				await Promise.all(
					this.selectedProjects.map((project) => {
						return this.updateProjectDetails({
							payload: {
								isDisabled: true,
							},
							_id: project._id,
						});
					})
				);
				this.closeOverlayLoader();
				this.getAllProjects();
			} catch (error) {
				console.error("Error deleting projects:", error);
			}
			this.selectedProjects = [];
		},
		restoreDeletedProject(data) {
			if (!data) return;
			const restoreConfirmation = confirm(
				`Do you want to restore the ${data.name} project`
			);
			if (!restoreConfirmation) return;
			this.updateProjectDetails({
				payload: {
					isDisabled: false,
				},
				_id: data._id,
			});
			this.closeOverlayLoader();
			this.getAllProjects();
		},
		async handleDeleteProjects() {
			if (!this.selectedProjects.length) return;

			const deleteConfirmation = confirm(
				"Are you sure you want to permanently delete the selected projects?"
			);

			if (!deleteConfirmation) return;

			try {
				await Promise.all(
					this.selectedProjects.map((project) => {
						return this.removeProjects({ _id: project._id });
					})
				);
				this.closeOverlayLoader();
				this.getAllProjects();
			} catch (error) {
				console.error("Error deleting projects:", error);
			}
			this.selectedProjects = [];
		},

		handleSelectedProjectListUpdates(data) {
			if (!data) return;
			this.selectedProjects = data;
		},
		handleProjectsSerach() {
			if (this.searchTimeoutRef) {
				clearTimeout(this.searchTimeoutRef);
				this.setTimeoutRef = null;
			}
			this.searchTimeoutRef = setTimeout(() => {
				this.getAllProjects();
			}, 300);
		},
		handleDeleteClick() {
			if (this.showDisabledProjects) {
				this.handleDeleteProjects();
				return;
			}
			this.handleDisablingProjects();
		},
		handleShowDisabledProjects() {
			this.showDisabledProjects = !this.showDisabledProjects;
			this.filter = {
				isDisabled: this.showDisabledProjects,
			};
			this.getAllProjects();
		},
	},
};
</script>

<style lang="scss" scoped>
.project {
	height: auto;
	width: 100%;
	background: $primary-background-color;

	&_list {
		width: 94%;
		height: auto;
		margin: 2.25rem auto 0 auto;
		background: $primary-white;

		padding: 47.2px 21.92px 47.2px 33.92px;

		&-header {
			width: 100%;
			display: flex;
			justify-content: space-between;

			&-container {
				flex: 0 0 22%;
				.search-box {
					margin-top: 0.5rem;
					.v-text-field--outlined {
						border-radius: 0;
					}
				}
				&-items {
					flex: 0 0 100%;
					height: 32.96px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;

					&-btn {
						flex: 0 0 70%;
						height: 100%;
						height: 32.96px;
						margin-right: -5.6px;
					}
					&-refreshBtn {
						flex: 0 0 32.96px;
						height: 32.96px;
						background-color: $secondary-blue;
						&:hover {
							cursor: pointer;
						}
					}
					&-menuBtn {
						flex: 0 0 32.96px;
						height: 32.96px;
						background-color: $secondary-blue;
					}

					&-label {
						flex: 0 0 20%;
						color: $text-color-1;
						font-size: 12.8px;
						font-weight: 500;
						letter-spacing: 0.08px;
					}
					&-input {
						flex: 0 0 70%;
						height: 32.96px;
						border-radius: 0.8px;
						border: 0.0617rem solid rgba(181, 181, 181, 0.62);
						margin-top: 22.4px;

						&::placeholder {
							color: $placeholder-col-1;
							font-size: 11.84px;
							font-weight: 400;
							letter-spacing: 0.08px;
							padding-left: 8.8px;
						}
					}
				}
			}
		}
	}

	&_table {
		width: 94%;
		margin: 0 auto;
	}
}
</style>

<style lang="scss">
.primary-heading {
	color: #292f38;
	font-size: 1.5rem;
	font-weight: 500;
	letter-spacing: 0.0056rem;

	& svg {
		stroke-width: 2;
		margin-right: 1rem;
	}
}

.header_btn {
	font-size: 0.875rem;
	font-weight: 500;
	letter-spacing: 0.06rem;
	height: 2.06rem;
	background-color: $secondary-blue;
	color: $primary-white;
	padding: 0 0.68rem;
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.187rem;

	&-svg {
		height: 1.68rem;
		width: 1.56rem;
		stroke: $primary-white;
	}
}

.primary-svg {
	width: 1.68rem;
	height: 1.68rem;
	stroke: $primary-white;
}

.item-center {
	display: grid;
	place-items: center;
}
.arrow-svg {
	&:hover {
		cursor: pointer;
	}
}
.project {
	&_list {
		&-header {
			&-container {
				&-items {
					.action-icon {
						background: $secondary-blue;
						i {
							color: $primary-white;
						}
					}
				}
			}
		}
	}
}
</style>
