import { routes } from "./base/apiRoutes";
import {
	deleteAPIResponse,
	getAPIResponse,
	postAPIResponse,
	putAPIResponse,
} from "./base/api";

const handle401 = (e) => {
	if (
		e.response &&
		(e.response.status === 401 || e.response.status === 403)
	) {
		localStorage.removeItem("Authorization");
		// location.href = location.origin + location.pathname;
	}
};

export const getAllProjects = async (payload) => {
	const { page, limit, search, filter } = payload;
	const reqestUrl = `${
		routes.project
	}?page=${page}&limit=${limit}&search=${search}&filter=${JSON.stringify(
		filter
	)}`;
	return getAPIResponse(reqestUrl).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const createProject = async (data) => {
	return postAPIResponse(routes.project, data).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const getProjectDetails = async (data) => {
	const { _id } = data;
	const requestRoute = routes.project + _id;
	return getAPIResponse(requestRoute, data).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const updateProject = async (data) => {
	const { payload, _id } = data;
	const requestUrl = routes.project + _id;
	return putAPIResponse(requestUrl, payload).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};
export const deleteProject = async (data) => {
	const { _id } = data;
	const requestRoute = routes.project + _id;
	return deleteAPIResponse(requestRoute).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const uploadFloorImage = async (data) => {
	const requestUrl = routes.project + "UploadFloorImage";
	return postAPIResponse(requestUrl, data).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};
export const removeFloorImage = async (data) => {
	const requestUrl = routes.project + "DeleteFloorImage";
	return postAPIResponse(requestUrl, data).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const getPrimaryUnits = async () => {
	return getAPIResponse(routes.primaryUnits).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const getPipingSizes = async (payload) => {
	const { search } = payload;
	const requestUrl = `${routes.pipingSizes}?search=${search}`;
	return getAPIResponse(requestUrl).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const fetchUserloginUrl = async () => {
	return getAPIResponse(`${routes.Oauth}user-login-url`).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const getAccessToken = async () => {
	return getAPIResponse(`${routes.Oauth}access-token`).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};

export const logout = async () => {
	return postAPIResponse(`${routes.auth}logout`).catch((e) => {
		handle401(e);
		throw new Error(e);
	});
};
