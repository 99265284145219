const requireModule = require.context(".", true, /.(jpg|png|gif|svg)$/);
// requireModule.keys().forEach(element => {
//     console.log("images" + element)
// });
var images = {};
//dublicate issue
requireModule.keys().map((item) => {
	images[item.substring(item.lastIndexOf("/") + 1)] =
		requireModule(item).default;
});
export default images;
